var createPopupHTML = function (element, fancyName, labelKeys, labelClass) {
    return fancyName
        ? "<div class=\"".concat(labelClass || '', "\">").concat(fancyName, "</div>")
        : typeof labelKeys === 'undefined'
            ? null
            : "<div class=\"".concat(labelClass || '', "\">").concat(labelKeys
                .flatMap(function (labelKey) {
                return element[labelKey.propName]
                    ? ["".concat(labelKey.divider).concat(element[labelKey.propName])]
                    : [];
            })
                .join(''), "</div>");
};
export default createPopupHTML;
