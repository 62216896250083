import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
export var createStyle = function (color, fill, width) {
    if (width === void 0) { width = 2; }
    return new Style({
        stroke: color ? new Stroke({ color: color, width: width }) : undefined,
        fill: fill ? new Fill({ color: fill }) : undefined,
    });
};
