var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Feature } from 'ol';
import { Stroke, Style } from 'ol/style';
import { getCenter } from 'ol/extent';
import { LineString } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { toPolygon } from '../utils/convertBetweenPolygonElement';
export default function toggleRouteLayer(map) {
    return function toggleLayer(id, routeData) {
        var _a, _b;
        var ephemeralLayer = map
            .getAllLayers()
            .find(function (layer) { return layer.get('layerName') === 'router'; });
        // We previously had a layer
        if (typeof ephemeralLayer !== 'undefined') {
            var ephemeralSource = ephemeralLayer.getSource();
            var _c = __read(ephemeralSource.getFeatures(), 1), feature = _c[0];
            var featureId = feature.get('id');
            ephemeralSource.clear();
            map.removeLayer(ephemeralLayer);
            // We want to clear the previous layer
            if (featureId === id) {
                return;
            }
        }
        if (typeof routeData !== 'undefined') {
            var route = routeData.route, destination = routeData.destination;
            var geometry = toPolygon(route);
            // Transform the route to EOV coordinate system if necessary
            if (((_b = (_a = route.crs) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.name) !== 'EPSG:23700') {
                geometry.transform(route.crs.properties.name, 'EPSG:23700');
            }
            // Create a new feauture from the geometry
            var feature = new Feature({
                geometry: geometry,
                id: id,
            });
            // Get the endpoint of the route from Google
            var endpoint = geometry.getLastCoordinate();
            // Get the center of the object we want to route to
            var destPoint = getCenter(toPolygon({ geom: destination }).getExtent());
            // Create the line missing from the Google provided route
            var missingSegment = new Feature({
                geometry: new LineString([endpoint, destPoint]),
            });
            // Add the lines to a new source
            var source = new VectorSource({
                features: [feature, missingSegment],
            });
            // Create a new layer with the route
            var newLayer = new VectorLayer({
                source: source,
                style: new Style({
                    stroke: new Stroke({
                        width: 6,
                        color: 'red',
                        lineDash: [0.1, 9],
                    }),
                }),
                properties: {
                    layerName: 'router',
                    ignoreClick: true,
                },
                zIndex: 1000,
            });
            map.addLayer(newLayer);
            // Zoom to the route
            map.getView().fit(source.getExtent(), {
                duration: 2000,
                padding: [300, 300, 300, 300],
            });
        }
    };
}
