var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Circle, LineString, MultiLineString, MultiPolygon, Polygon } from 'ol/geom';
/**
 * Between the MapElements coming from the API and the OpenLayers geometries,
 * we have an isomorphism (they can be converted back and forth without any data loss).
 *
 * For this there is a need for a pair of functions, that can convert between them.
 */
/**
 * Convert MapElement to OpenLayers' Polygon or MultiPolygon
 *
 * @param {MapElement} element the MapElement object (has to have geom field)
 * @return {Polygon | MultiPolygon} the OpenLayers Polygon or MultiPolygon instance
 * @throws Error if the passed shape is not in a convertible format
 */
export var toPolygon = function (element) {
    var geom = element.geom, properties = __rest(element, ["geom"]);
    switch (geom.type) {
        case 'Polygon': {
            var shape = new Polygon(geom.coordinates);
            shape.setProperties(properties);
            return shape;
        }
        case 'MultiPolygon': {
            var shape = new MultiPolygon(geom.coordinates);
            shape.setProperties(properties);
            return shape;
        }
        case 'LineString': {
            var shape = new LineString(geom.coordinates);
            shape.setProperties(properties);
            return shape;
        }
        case 'MultiLineString': {
            var shape = new MultiLineString(geom.coordinates);
            shape.setProperties(properties);
            return shape;
        }
        case 'Point': {
            var shape = new Circle(geom.coordinates, properties.size);
            shape.setProperties(properties);
            return shape;
        }
        default:
            throw new Error('Cannot convert shape to OpenLayers polygon.');
    }
};
/**
 * Convert OpenLayers' Polygon or MultiPolygon instances to POJO MapElements object
 * (it reconstructs the original object's properties).
 *
 * @param {Polygon | MultiPolygon} shape the OpenLayers Polygon or MultiPolygon instance
 * @return {MapElement} the MapElement object (has geom field for geometry and other fields from the original metadata)
 */
export var toElement = function (shape) {
    if (shape instanceof MultiPolygon) {
        return __assign(__assign({}, shape.getProperties()), { geom: {
                type: 'MultiPolygon',
                coordinates: shape.getCoordinates(),
            } });
    }
    if (shape instanceof Polygon) {
        return __assign(__assign({}, shape.getProperties()), { geom: {
                type: 'Polygon',
                coordinates: shape.getCoordinates(),
            } });
    }
    if (shape instanceof LineString) {
        return __assign(__assign({}, shape.getProperties()), { geom: {
                type: 'LineString',
                coordinates: shape.getCoordinates(),
            } });
    }
    if (shape instanceof MultiLineString) {
        return __assign(__assign({}, shape.getProperties()), { geom: {
                type: 'MultiLineString',
                coordinates: shape.getCoordinates(),
            } });
    }
    if (shape instanceof Circle) {
        return __assign(__assign({}, shape.getProperties()), { geom: {
                type: 'Point',
                coordinates: shape.getCenter(),
            } });
    }
    throw new Error('Cannot convert to MapElement.');
};
