var toggleSelection = function (map, selector, evt) {
    if (evt.type === 'singleclick') {
        // Find previously selected features
        var prevSelected_1 = selector
            .getFeatures()
            .getArray()
            .map(function (f) { return f.getGeometry().get('element').id; });
        // Check if there are any features at the position of the click
        return (map.forEachFeatureAtPixel(evt.pixel, function (feature) {
            var _a;
            var element = (_a = feature.getGeometry()) === null || _a === void 0 ? void 0 : _a.get('element');
            // Check if the clicked features is already selected
            if (typeof element !== 'undefined' && prevSelected_1.includes(element.id)) {
                // Return true to fire toggle
                return true;
            }
            // New feature selected or no feature selected
            // clear previous selection to have only one selected element
            // Then return true to fire toggle
            selector.getFeatures().clear();
            return true;
        }) || false);
    }
    return false;
};
export default toggleSelection;
