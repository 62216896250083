import { Fill, Style, Text } from 'ol/style';
import { createStyle } from './createStyle';
var labelStyle = new Style({
    text: new Text({
        font: '14px Calibri,sans-serif',
        overflow: false,
        fill: new Fill({
            color: '#000',
        }),
        backgroundFill: new Fill({
            color: '#fff',
        }),
        padding: [4, 2, 2, 4],
    }),
});
export var createLabel = function (feature, childBorderColor, childFillColor, labelKeys) {
    var _a;
    if (labelKeys === void 0) { labelKeys = []; }
    var element = feature.getGeometry().get('element');
    var label = (_a = element.fancyName) !== null && _a !== void 0 ? _a : labelKeys
        .flatMap(function (labelKey) {
        return element[labelKey.propName]
            ? ["".concat(labelKey.divider).concat(element[labelKey.propName])]
            : [];
    })
        .join('');
    labelStyle.getText().setText(label || '');
    return [createStyle(childBorderColor, childFillColor), labelStyle];
};
