import { ZoomToExtent } from 'ol/control';
/**
 * Sets the behaviour of the ZoomToExtent button
 *
 * @param map the map object
 */
export var setZoomToExtent = function (map, extent) {
    // Remove all ZoomToExtent controls from the map object
    map.getControls()
        .getArray()
        .forEach(function (control) {
        if (control instanceof ZoomToExtent) {
            map.removeControl(control);
        }
    });
    map.addControl(new ZoomToExtent({
        extent: extent,
    }));
};
