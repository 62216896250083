var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import VectorLayer from 'ol/layer/Vector';
import { createStyle } from '../utils/createStyle';
import { createVectorSource } from '../utils/createVectorSource';
import { createLabel } from '../utils/createLabel';
var addChildLayers = function (map, parentSource, zIndex, childLayerOptions) {
    return childLayerOptions.map(function (childLayerData, i) {
        var tableName = childLayerData.tableName, parentIdKey = childLayerData.parentIdKey, labelKeys = childLayerData.labelKeys, labelClass = childLayerData.labelClass, minZoomKey = childLayerData.minZoomKey, maxZoomKey = childLayerData.maxZoomKey, childBorderColor = childLayerData.childBorderColor, childFillColor = childLayerData.childFillColor, _a = childLayerData.ignoreChildClick, ignoreChildClick = _a === void 0 ? false : _a, _b = childLayerData.selectable, selectable = _b === void 0 ? false : _b, _c = childLayerData.labelAlways, labelAlways = _c === void 0 ? false : _c;
        // Define parentExtents to use it when setting zoomToExtent
        var parentExtents = parentSource.getFeatures().reduce(function (p, c) {
            var _a;
            var geometry = c.getGeometry();
            if (typeof geometry !== 'undefined') {
                var extent = geometry.getExtent();
                var id = geometry.get('id');
                return __assign(__assign({}, p), (_a = {}, _a[id] = extent, _a));
            }
            return p;
        }, {});
        var childLayer = new VectorLayer({
            source: createVectorSource(tableName, parentIdKey, parentExtents, labelKeys, labelClass),
            // Setting minZoom for a high initial value as it will be set later when parent becomes Mr. Lonely in the view
            minZoom: 100,
            style: labelAlways
                ? function (feature) { return createLabel(feature, childBorderColor, childFillColor, labelKeys); }
                : createStyle(childBorderColor, childFillColor),
            declutter: true,
            zIndex: zIndex + i + 1,
            properties: __assign({ layerName: tableName, ignoreClick: ignoreChildClick, selectable: selectable, minZoomKey: minZoomKey }, (maxZoomKey && { maxZoomKey: maxZoomKey })),
        });
        map.addLayer(childLayer);
        return childLayer;
    });
};
export default addChildLayers;
