export var setLayerVisibility = function (map) {
    return function (layerName, visible) {
        var layerToToggle = map.getAllLayers().find(function (l) { return l.get('layerName') === layerName; });
        var popupElement = map.getOverlayById('popup').getElement();
        if (typeof layerToToggle !== 'undefined') {
            layerToToggle.setVisible(typeof visible !== 'undefined' ? visible : !layerToToggle.getVisible());
            // Get the new visibility
            var isVisible = layerToToggle.getVisible();
            // Set hover and click based on visibility
            if (layerName === 'hoverLayer' && typeof popupElement !== 'undefined') {
                layerToToggle.set('ignoreClick', !isVisible);
                popupElement.setAttribute('style', "display: ".concat(isVisible ? 'block' : 'none'));
            }
            // Return the new visibility
            return isVisible;
        }
        return false;
    };
};
