import { Feature } from 'ol';
import { getCenter } from 'ol/extent';
import { GeometryCollection, Point } from 'ol/geom';
import { fromExtent } from 'ol/geom/Polygon';
import { Cluster, Vector as VectorSource } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { createMarkerOverlay } from './createMarkerOverlay';
import { toElement } from './convertBetweenPolygonElement';
import zoomTo from '../actions/zoomTo';
/**
 * Add marker to layers that have marker property
 *
 * @param markers optional layerproperty containing the svg of marker and marker name
 * @param map the map object
 * @param vectorSource the source of the layer we want to add markers to
 * @returns a layer of icons to cluster
 */
export var createMarkers = function (markers, map, vectorSource) {
    // Create an empty clustersource
    var clusterSource = new VectorSource();
    var clusterLayerSource = new Cluster({
        distance: 40,
        minDistance: 20,
        source: clusterSource,
    });
    // Detect the change of cluster, hide/show overlays based on current anchors
    clusterLayerSource.on('change', function (event) {
        if (event.target) {
            var currentZoom_1 = map.getView().getZoom();
            var markerAnchors = clusterLayerSource.getFeatures();
            var anchors_1 = markerAnchors.map(function (anchor) {
                var features = anchor.get('features');
                var anchorId = "icon-".concat(features
                    .map(function (f) { return f.get('iconId'); })
                    .join('-'));
                // Get the extent that all features of cluster fit in
                var anchorExtent = new GeometryCollection(features.map(function (feature) { return fromExtent(feature.get('extent')); })).getExtent();
                var zoomToElement = toElement(fromExtent(anchorExtent));
                var anchorPosition = getCenter(anchorExtent);
                return {
                    anchorId: anchorId,
                    anchorPosition: anchorPosition,
                    zoomToElement: zoomToElement,
                };
            });
            var markerOverlays = map
                .getOverlays()
                .getArray()
                .filter(function (o) { var _a; return ((_a = o.getId()) === null || _a === void 0 ? void 0 : _a.toString().split('-')[0]) === 'icon'; });
            // Set markers to visible/invisible based on corresponding cluster feature presence
            markerOverlays.forEach(function (markerOverlay) {
                var markerId = markerOverlay.getId();
                var markerElement = markerOverlay.getElement();
                if (typeof markerId !== 'undefined' && typeof markerElement !== 'undefined') {
                    var anchor = anchors_1.find(function (a) { return a.anchorId === markerId.toString(); });
                    if (typeof anchor !== 'undefined' &&
                        typeof currentZoom_1 !== 'undefined' &&
                        currentZoom_1 <= 12) {
                        markerElement.setAttribute('style', 'display: block');
                        markerOverlay.set('elementForZoom', anchor.zoomToElement);
                        markerOverlay.setPosition(anchor.anchorPosition);
                    }
                    else {
                        markerElement.setAttribute('style', 'display: none');
                    }
                }
            });
        }
    });
    // Create the cluster
    var clusterLayer = new VectorLayer({
        source: clusterLayerSource,
        style: function (markerAnchor) {
            // Add Overlays with html content to the desired position of the map
            var overlays = map.getOverlays().getArray();
            var markerOverlay = createMarkerOverlay(markerAnchor, markers, overlays);
            var markerElement = markerOverlay.getElement();
            if (typeof markerOverlay.getId() !== 'undefined' &&
                typeof markerElement !== 'undefined') {
                // Add click event for markerOverlay to zoom to the represented extent
                markerElement.addEventListener('click', function (event) {
                    if (event.target) {
                        zoomTo(map)(markerOverlay.get('elementForZoom'), {
                            padding: [50, 50, 50, 50],
                        });
                    }
                });
                map.addOverlay(markerOverlay);
            }
        },
        properties: {
            layerName: markers.markerName,
        },
    });
    // Add the features desired to be marked to the source of the cluster
    vectorSource.forEachFeature(function (feature) {
        var geometry = feature.getGeometry();
        if (typeof geometry !== 'undefined') {
            var name_1 = geometry.get('name');
            var id = geometry.get('id');
            var extent = geometry.getExtent();
            var point = new Point(getCenter(extent));
            clusterSource.addFeature(new Feature({
                geometry: point,
                extent: extent,
                name: name_1,
                iconId: id,
            }));
        }
    });
    // Add cluster to the map
    map.addLayer(clusterLayer);
};
