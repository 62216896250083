import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon';
import { toPolygon } from '../utils/convertBetweenPolygonElement';
var zoomTo = function (map) {
    return function (element, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.duration, duration = _c === void 0 ? 2000 : _c, _d = _b.padding, padding = _d === void 0 ? [300, 300, 300, 300] : _d;
        var view = map.getView();
        if (typeof element === 'undefined') {
            var extent = map.get('countryExtent');
            view.fitInternal(polygonFromExtent(extent), { duration: duration, padding: padding });
        }
        else {
            view.fit(toPolygon(element).getExtent(), { duration: duration, padding: padding });
        }
    };
};
export default zoomTo;
