import { Overlay } from 'ol';
import { getCenter } from 'ol/extent';
import { GeometryCollection } from 'ol/geom';
import { fromExtent } from 'ol/geom/Polygon';
import { toElement } from './convertBetweenPolygonElement';
/**
 * Creates an Overlay for each marker
 *
 * @param markerAnchor clusterfeature with marker position
 * @param markers html data for default/aggregated markers
 * @param overlays the existing overlays on the map
 * @returns new Overlay
 */
export var createMarkerOverlay = function (markerAnchor, markers, overlays) {
    var features = markerAnchor.get('features');
    var size = features.length;
    // Get the extent that all features of cluster fit in
    var anchorExtent = new GeometryCollection(features.map(function (feature) { return fromExtent(feature.get('extent')); })).getExtent();
    var anchorPosition = getCenter(anchorExtent);
    var markerElement = document.createElement('div');
    var markerHTML = markers.markerDefault;
    var text = features[0].get('name');
    var iconId = "icon-".concat(features[0].get('iconId').toString());
    if (size > 1) {
        iconId = "icon-".concat(features.map(function (f) { return f.get('iconId'); }).join('-'));
        markerHTML = markers.markerAggregated;
        text = size.toString();
    }
    else {
        markerElement.setAttribute('id', features[0].get('iconId'));
    }
    markerElement.classList.add('marker');
    markerElement.innerHTML = markerHTML;
    var textElement = markerElement.getElementsByTagName('p').item(0);
    if (textElement) {
        textElement.innerText = text;
    }
    // Return a new Overlay if it's not existing
    if (!overlays
        .filter(function (o) { var _a; return ((_a = o.getId()) === null || _a === void 0 ? void 0 : _a.toString().split('-', 1)[0]) === 'icon'; })
        .map(function (o) { return o.getId(); })
        .includes(iconId)) {
        var markerOverlay = new Overlay({
            id: iconId,
            positioning: size > 1 ? 'center-center' : 'bottom-center',
            offset: size > 1 ? [-10, -40] : [0, 0],
            element: markerElement,
            autoPan: false,
        });
        markerOverlay.setPosition(anchorPosition);
        markerOverlay.set('elementForZoom', toElement(fromExtent(anchorExtent)));
        return markerOverlay;
    }
    // The overlay with this iconId exist, return an empty Overlay
    return new Overlay({});
};
