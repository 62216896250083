import { Select } from 'ol/interaction';
import zoomTo from './zoomTo';
var iterations = 0;
/**
 * Tries to select a feature with the given id every 100ms until the data for the layer is loaded.
 *
 * @param select the Select interaction
 * @param layer the layer which we're selecting from
 * @param id the id of the element we want to select
 */
export function selectFeature(select, layer, id) {
    if (iterations === 100) {
        return;
    }
    var feature = layer
        .getSource()
        .getFeatures()
        .find(function (f) { var _a; return ((_a = f.getGeometry()) === null || _a === void 0 ? void 0 : _a.get('element').id) === id; });
    if (feature) {
        select.getFeatures().clear();
        select.getFeatures().extend([feature]);
        iterations = 0;
        return;
    }
    iterations++;
    setTimeout(function () { return selectFeature(select, layer, id); }, 100);
}
var addSelection = function (map) { return function (selectorId, element) {
    var select = map
        .getInteractions()
        .getArray()
        .find(function (int) { return int.get('name') === selectorId; });
    var isSelect = typeof select !== 'undefined' && select instanceof Select;
    if (typeof element === 'undefined') {
        if (isSelect) {
            select.getFeatures().clear();
        }
        return;
    }
    var geom = element.geom, id = element.id, layerName = element.layerName;
    var layer = map.getAllLayers().find(function (l) { return l.get('layerName') === layerName; });
    zoomTo(map)({ geom: geom });
    if (typeof layer !== 'undefined' && isSelect) {
        selectFeature(select, layer, id);
    }
}; };
export default addSelection;
