import Select from 'ol/interaction/Select';
import { setZoomToExtent } from '../actions/setZoomToExtent';
import zoomTo from '../actions/zoomTo';
import toggleSelection from '../events/toggleSelection';
import { createStyle } from './createStyle';
var createSelect = function (map, children, selectColor, selectFill) {
    var selectInteraction = new Select({
        toggleCondition: function (evt) {
            return toggleSelection(map, this, evt);
        },
        multi: false,
        layers: children.filter(function (child) { return child.get('selectable'); }),
        style: createStyle(selectColor, selectFill),
    });
    selectInteraction.setProperties({ name: 'select' });
    selectInteraction.getFeatures().on('add', function (evt) {
        var element = evt.element.getGeometry().get('element');
        var itemGeometry = element.itemGeometry, parentExtent = element.parentExtent;
        window.widget.selectionEmitter.emit('selection', element);
        map.set('mapMove', 'select');
        zoomTo(map)({ geom: itemGeometry }, { padding: [10, 10, 10, 10] });
        setZoomToExtent(map, parentExtent);
    });
    selectInteraction.getFeatures().on('remove', function () {
        window.widget.selectionEmitter.emit('selection', false);
        // Set the ZoomToExtent control to deafult
        map.addControl(map.get('defaultZoom'));
    });
    map.addInteraction(selectInteraction);
    map.on('movestart', function () {
        var _a, _b, _c, _d, _e;
        var selected = (_a = selectInteraction.getFeatures().item(0)) === null || _a === void 0 ? void 0 : _a.getGeometry().get('element').id;
        var marked = (_c = (_b = map
            .getAllLayers()
            .find(function (layer) { return layer.get('layerName') === 'marked'; })) === null || _b === void 0 ? void 0 : _b.getSource().getFeatures()[0]) === null || _c === void 0 ? void 0 : _c.get('id');
        var routed = (_e = (_d = map
            .getAllLayers()
            .find(function (layer) { return layer.get('layerName') === 'router'; })) === null || _d === void 0 ? void 0 : _d.getSource().getFeatures()[0]) === null || _e === void 0 ? void 0 : _e.get('id');
        if (map.get('mapMove') !== 'select' && selected !== marked && selected !== routed) {
            selectInteraction.getFeatures().clear();
        }
        map.set('mapMove', false);
    });
};
export default createSelect;
