var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import 'ol/ol.css';
import './index.css';
import { Map, Overlay, View } from 'ol';
import { applyTransform } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
import { ZoomToExtent, defaults as defaultControls } from 'ol/control';
import { get as getProjection, getTransform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import createApi from './actions/api';
import country from './mocks/country.json';
import { toPolygon } from './utils/convertBetweenPolygonElement';
// Hungary's projection from https://epsg.io/?format=json&q=23700
var projectionName = 'EPSG:23700';
var worldExtent = [24.5, 51.5, 14.5, 42];
proj4.defs(projectionName, '+proj=somerc +lat_0=47.14439372222222 +lon_0=19.04857177777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=52.17,-71.82,-14.9,0,0,0,0 +units=m +no_defs');
register(proj4);
var countryExtent = toPolygon({
    geom: JSON.parse(country.country.polygon),
}).getExtent();
var newProj = getProjection(projectionName);
var fromLonLat = getTransform('EPSG:4326', newProj);
newProj.setWorldExtent(worldExtent);
var extent = applyTransform(worldExtent, fromLonLat, undefined, 8);
newProj.setExtent(extent);
var view = new View({
    projection: newProj,
    extent: extent,
    minResolution: 0.028,
});
view.fit(countryExtent);
var defaultZoom = new ZoomToExtent({
    extent: countryExtent,
});
var map = new Map({
    controls: defaultControls().extend([defaultZoom]),
    layers: [
        new TileLayer({
            source: new XYZ({
                url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                tilePixelRatio: 2,
                maxZoom: 19,
            }),
            zIndex: 0,
            properties: {
                layerName: 'streetMap',
            },
        }),
    ],
    target: 'root',
    view: view,
});
view.fitInternal(fromExtent(countryExtent));
window.onresize = function () {
    map.set('mapMove', 'select');
    setTimeout(function () {
        var _a;
        view.fitInternal(fromExtent((_a = map.get('lastExtent')) !== null && _a !== void 0 ? _a : countryExtent), {
            duration: 1000,
            padding: [0, 0, 0, 0],
        });
    }, 20);
};
// Set a property on map object for extent of country so we can access it elsewhere
map.set('countryExtent', countryExtent);
// Set a default ZoomToExtent control on a map property so we can set the control back later
map.set('defaultZoom', defaultZoom);
map.on('moveend', function () { return map.set('lastExtent', map.getView().calculateExtent(map.getSize())); });
// Define the popup object
var popupElement = document.createElement('div');
popupElement.setAttribute('id', 'popup');
// Create the overlay for popups
var popup = new Overlay({
    positioning: 'center-center',
    id: 'popup',
    element: popupElement,
    stopEvent: false,
    className: 'popup',
});
map.addOverlay(popup);
window.widget = createApi(map);
// Add Hungary outline
// TODO: make border not clickable
window.widget.addLayer([
    __assign(__assign({}, country.country), { geom: JSON.parse(country.country.polygon) }),
], 1, {
    borderColor: '#D99D26',
    layerName: 'country',
    ignoreClick: true,
});
// Add testing data for development
if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    import('./development.css');
    import('./mocks/markers.json').then(function (_a) {
        var markers = _a.markers;
        import('./mocks/graveyards.json').then(function (_a) {
            var graveyards = _a.graveyards;
            return window.widget.addLayer(graveyards.map(function (g) { return (__assign(__assign({}, g), { geom: g.geom })); }), 1, {
                ignoreClick: true,
                layerName: 'graveYardsLayer',
                hoverColor: 'rgba(250,250,10,1',
                hoverFill: 'rgba(250,250,10,.6)',
                selectColor: 'rgba(250,0,0,1)',
                selectFill: 'rgba(250,0,0,.3)',
                markers: markers.find(function (marker) { return marker.markerName === 'graveYardsLayer'; }),
                tiles: true,
                childLayers: [
                    {
                        tableName: 'Parcel',
                        parentIdKey: 'graveyardId',
                        minZoomKey: 'parcelZoom',
                        maxZoomKey: 'graveZoom',
                        childBorderColor: '#F5F53D',
                        childFillColor: 'rgba(245, 245, 61, 0.3)',
                        labelKeys: [
                            {
                                propName: 'name',
                                divider: '',
                            },
                        ],
                        labelClass: 'label-grave',
                        labelAlways: true,
                    },
                    {
                        tableName: 'Grave',
                        parentIdKey: 'graveyardId',
                        minZoomKey: 'graveZoom',
                        childBorderColor: '#F5F53D',
                        childFillColor: 'rgba(245, 245, 61, 0.3)',
                        labelKeys: [
                            {
                                propName: 'parcel',
                                divider: '',
                            },
                            {
                                propName: 'row',
                                divider: '-',
                            },
                            { propName: 'pos', divider: '/' },
                        ],
                        labelClass: 'label-grave',
                        selectable: true,
                    },
                ],
            });
        });
    });
}
