var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { getCenter } from 'ol/extent';
var hoverAction = function (evt, map) {
    var _a = __read(evt.selected, 1), selected = _a[0];
    // Get the popup object
    var popup = map.getOverlayById('popup');
    var element = popup.getElement();
    if (element) {
        if (!selected) {
            document.getElementById('popup').setAttribute('style', 'display: none');
            element.innerHTML = '';
            return;
        }
        var popupHTML = selected.getGeometry().get('element').popupHTML;
        var currentZoomLevel = map.getView().getZoom() || 12;
        // Calculate vertical offset based on current zoom
        var offsetTop = -3 * currentZoomLevel;
        // Move the popup for the current feature and fill it with the HTML of the feature
        // if (currentZoomLevel > 13) {
        popup.setPosition(getCenter(selected.get('geometry').getExtent()));
        popup.setOffset([0, offsetTop]);
        element.innerHTML = popupHTML;
        element.setAttribute('data', offsetTop.toString());
        element.setAttribute('style', 'display: block');
        // }
    }
};
export default hoverAction;
