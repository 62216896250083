var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Select } from 'ol/interaction';
import { transform } from 'ol/proj';
import { selectFeature } from './addSelection';
var zoomToCoord = function (map) {
    return function (coords, layerName, shouldSelect, zoom) {
        if (shouldSelect === void 0) { shouldSelect = true; }
        if (zoom === void 0) { zoom = 18; }
        var done = false;
        if (typeof layerName !== 'string') {
            throw new Error("The param 'layerName' is required");
        }
        if (typeof coords[0] !== 'number' || typeof coords[1] !== 'number') {
            throw new Error("Coordinates are required in number format. The input was x: ".concat(typeof coords[0], " and y: ").concat(typeof coords[1]));
        }
        var transformedCoords = transform(coords, 'EPSG:4326', 'EPSG:23700');
        var view = map.getView();
        var gravesLayer = map.getAllLayers().find(function (e) { return e.get('layerName') === layerName; });
        if (!gravesLayer) {
            throw new Error("No such layer: ".concat(layerName));
        }
        var select = map
            .getInteractions()
            .getArray()
            .find(function (int) { return int.get('name') === 'select'; });
        view.setCenter(transformedCoords);
        view.setZoom(zoom);
        gravesLayer.addEventListener('postrender', function () {
            var _a;
            if (done) {
                return;
            }
            done = true;
            var gravesSource = gravesLayer.getSource();
            var features = gravesSource.getFeatures();
            var _b = __read(features.filter(function (feature) { var _a; return (_a = feature.getGeometry()) === null || _a === void 0 ? void 0 : _a.intersectsCoordinate(transformedCoords); }), 1), grave = _b[0];
            var id = (_a = grave.getGeometry().get('element')) === null || _a === void 0 ? void 0 : _a.id;
            if (typeof gravesLayer !== 'undefined' &&
                typeof select !== 'undefined' &&
                select instanceof Select &&
                id &&
                shouldSelect) {
                selectFeature(select, gravesLayer, id);
            }
        });
    };
};
export default zoomToCoord;
