import { Feature } from 'ol';
import { XYZ, Vector as VectorSource } from 'ol/source';
import { Select } from 'ol/interaction';
import { pointerMove, singleClick } from 'ol/events/condition';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import { toPolygon } from '../utils/convertBetweenPolygonElement';
import { createStyle } from '../utils/createStyle';
import { createMarkers } from '../utils/createMarkers';
import hoverAction from '../events/hover';
import addChildLayers from './addChildLayers';
import createSelect from '../utils/createSelect';
var addLayer = function (map) {
    return function (elements, zIndex, _a) {
        var _b = _a === void 0 ? {} : _a, layerName = _b.layerName, borderColor = _b.borderColor, fillColor = _b.fillColor, hoverColor = _b.hoverColor, hoverFill = _b.hoverFill, markers = _b.markers, _c = _b.selectColor, selectColor = _c === void 0 ? 'rgba(250,0,0,1)' : _c, _d = _b.selectFill, selectFill = _d === void 0 ? 'rgba(250,0,0,.3)' : _d, _e = _b.ignoreClick, ignoreClick = _e === void 0 ? false : _e, _f = _b.tiles, tiles = _f === void 0 ? false : _f, childLayers = _b.childLayers;
        var vectorSource = new VectorSource();
        vectorSource.addFeatures(elements.map(function (element) { return new Feature(toPolygon(element)); }));
        var newLayer = new VectorLayer({
            source: vectorSource,
            style: createStyle(borderColor, fillColor),
            properties: {
                layerName: layerName,
                ignoreClick: ignoreClick,
            },
            zIndex: zIndex,
        });
        map.addLayer(newLayer);
        // If features have tilesources, add tilelayer for each element
        if (tiles) {
            elements.forEach(function (element) {
                if (element.tiles) {
                    map.addLayer(new TileLayer({
                        source: new XYZ({
                            url: "".concat(element.tiles, "/{z}/{x}/{y}.png"),
                        }),
                        preload: Infinity,
                        properties: {
                            layerName: "".concat(element.id, "-orto"),
                            ignoreClick: true,
                        },
                        minZoom: element.tileZoom || 13,
                        zIndex: 0,
                    }));
                }
            });
        }
        // Init container for children
        var children = [];
        // If childLayers property provided, add these layers too
        if (typeof childLayers !== 'undefined') {
            // Making sure that the function can accept one childlayer or list of childlayers
            var childLayerOptions = Array.isArray(childLayers) ? childLayers : [childLayers];
            // Add childlayers
            children = addChildLayers(map, vectorSource, zIndex, childLayerOptions);
            // If hoverColor exists add hover interaction on children
            if (hoverColor || hoverFill) {
                var hoverInteraction = new Select({
                    condition: pointerMove,
                    layers: children,
                    style: createStyle(hoverColor, hoverFill),
                });
                hoverInteraction.on('select', function (evt) { return hoverAction(evt, map); });
                map.addInteraction(hoverInteraction);
            }
            // Create select interaction for selectable children
            createSelect(map, children, selectColor, selectFill);
            // Create select interaction with only zooming for children without selectable flag
            var onlyZoom_1 = new Select({
                condition: singleClick,
                multi: false,
                layers: children.filter(function (child) { return !child.get('selectable'); }),
            });
            onlyZoom_1.on('select', function (evt) {
                var geometry = evt.selected[0].getGeometry();
                if (typeof geometry !== 'undefined') {
                    map.getView().fit(geometry.getExtent(), {
                        duration: 2000,
                        padding: [10, 10, 10, 10],
                    });
                }
                onlyZoom_1.getFeatures().clear();
            });
            map.addInteraction(onlyZoom_1);
        }
        /**
         * Add marker to layers that have marker property
         *
         * We could move the first undefined check to the second if for a cleaner code
         * but that would lead to the unnecessary run of forEach on the sources of every layer
         */
        if (typeof markers !== 'undefined') {
            vectorSource.forEachFeature(function (feature) {
                var geometry = feature.getGeometry();
                if (typeof geometry !== 'undefined') {
                    createMarkers(markers, map, vectorSource);
                }
            });
        }
        map.on('moveend', function () {
            if (tiles) {
                // Find features of the layer in current extent
                var currentExtent = map.getView().calculateExtent(map.getSize());
                var features = vectorSource.getFeaturesInExtent(currentExtent);
                // If there's only one feature in the view from the layer set the visibility of orto layers and the breakpoint for children
                if (features.length === 1) {
                    // Get information stored in properties of featuregeometry
                    var props_1 = features[0].getGeometry();
                    if (typeof props_1 !== 'undefined') {
                        var id_1 = props_1.get('id');
                        // Find all orto layers
                        var ortoLayers = map
                            .getAllLayers()
                            .filter(function (l) { var _a; return (_a = l.get('layerName')) === null || _a === void 0 ? void 0 : _a.includes('orto'); });
                        // Set the ortolayer associated to this feature to visible and set all the other hidden
                        ortoLayers.forEach(function (layer) {
                            if (layer.get('layerName') === "".concat(id_1, "-orto")) {
                                layer.setVisible(true);
                                return;
                            }
                            layer.setVisible(false);
                        });
                        // Set zoom levels for childlayers
                        children.forEach(function (child) {
                            var minZoomKey = child.get('minZoomKey');
                            var maxZoomKey = child.get('maxZoomKey');
                            child.setMinZoom(props_1.get(minZoomKey));
                            // If maxZoomKey is set, maxZoom comes from the next siblings minZoom
                            if (typeof maxZoomKey !== 'undefined') {
                                child.setMaxZoom(props_1.get(maxZoomKey));
                            }
                        });
                    }
                }
            }
        });
        // Return the added layer object, so frontend can use it in selectElement
        return newLayer;
    };
};
export default addLayer;
