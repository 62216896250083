var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Feature } from 'ol';
import { Select } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import { Style, Icon } from 'ol/style';
import { Point } from 'ol/geom';
import { getCenter } from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import { createStyle } from '../utils/createStyle';
var iconStyle = new Style({
    image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 2,
        src: 'assets/img/icons/icon-location-marked.svg',
    }),
});
export function markSelected(map) {
    return function toggleMark() {
        // Get the select interaction to grab the element from
        var select = map
            .getInteractions()
            .getArray()
            .find(function (int) { return int.get('name') === 'select'; });
        // Move the feature to a new layer
        if (select instanceof Select) {
            var _a = __read(select.getFeatures().getArray(), 1), selected = _a[0];
            var geometry = selected.getGeometry();
            var element = geometry.get('element');
            var markLayer = map
                .getAllLayers()
                .find(function (layer) { return layer.get('layerName') === 'marked'; });
            // No marking whatsoever
            if (typeof markLayer === 'undefined') {
                var icon = new Feature({
                    geometry: new Point(getCenter(geometry.getExtent())),
                    id: element.id,
                });
                icon.setStyle(iconStyle);
                var source = new VectorSource({
                    features: [icon],
                });
                var markerLayer = new VectorLayer({
                    source: source,
                    style: createStyle('red', 'rgba(250,0,0,.3)'),
                    properties: {
                        layerName: 'marked',
                        ignoreClick: false,
                    },
                    zIndex: 1000,
                });
                map.addLayer(markerLayer);
                return;
            }
            // We previously had a mark
            var markedSource = markLayer.getSource();
            var _b = __read(markedSource.getFeatures(), 1), marked = _b[0];
            var markedId = marked.get('id');
            markedSource.clear();
            map.removeLayer(markLayer);
            // We want to clear the marking
            if (markedId === (element === null || element === void 0 ? void 0 : element.id)) {
                return;
            }
            // We want mark another
            toggleMark();
        }
    };
}
