import EventEmitter from 'events';
import { setLayerVisibility } from './setLayerVisibility';
import addLayer from './addLayer';
import zoomTo from './zoomTo';
import addSelection from './addSelection';
import zoomToCoord from './zoomToCoord';
import { markSelected } from './markSelected';
import toggleRouteLayer from './toggleRouteLayer';
var createApi = function (map) { return ({
    addLayer: function (elements, zIndex, options) {
        return addLayer(map)(elements, zIndex, options);
    },
    zoomTo: function (element, options) {
        zoomTo(map)(element, options);
    },
    setLayerVisibility: function (layerName, visible) {
        return setLayerVisibility(map)(layerName, visible);
    },
    addSelection: function (selectorId, element) { return addSelection(map)(selectorId, element); },
    markSelected: function () { return markSelected(map)(); },
    selectionEmitter: new EventEmitter(),
    zoomToCoord: function (X, Y, layerName, zoom) { return zoomToCoord(map)(X, Y, layerName, zoom); },
    toggleRouteLayer: function (id, routeData) { return toggleRouteLayer(map)(id, routeData); },
}); };
export default createApi;
